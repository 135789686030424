import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react"
import Layout from "../../../../components/layout/Layout/Layout"
import { UserService } from "../../../../services"
import UserStorage from "../../../../util/userStorage"
import theme from "../../../../Themes"

export default function UserProfileChangePassword() {
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState({
    password: "",
    newPassword: "",
    newPasswordConfirm: "",
  })

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
    })
    // eslint-disable-next-line
  }, [])

  const changePassword = () => {
    if (form.newPassword.length < 6) {
      toast({
        title: "A senha deve ter pelo menos 6 caracteres.",
        status: "error",
      })
      return
    }

    if (form.password === form.newPassword) {
      toast({
        title: "A nova senha não pode ser igual a atual",
        status: "error",
      })
      return
    }

    if (form.newPassword !== form.newPasswordConfirm) {
      toast({
        title: "A nova senha e a confirmação estão diferentes",
        status: "error",
      })
      return
    }

    UserService.changePassword(
      form.password,
      form.newPassword,
      form.newPasswordConfirm
    )
      .then((success) => {
        if (success) {
          navigate("/perfil")
        }
      })
      .catch((error) => {
        console.log("error", error)
        toast({
          title: error?.response?.data?.message,
          status: "error",
        })
      })
  }

  return (
    <Layout
      internal={true}
      menuSelected={"profile"}
      header={{
        title: "Alterar senha",
        backTo: "/perfil",
      }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        fontFamily="Raleway"
      >
        <Flex>
          <Text color="#2D3748" fontSize="1rem" fontWeight="700">
            Alterar senha
          </Text>
        </Flex>
        <VStack>
          <FormControl isRequired>
            <FormLabel>Senha atual</FormLabel>
            <Input
              size="lg"
              type="password"
              value={form.password}
              onChange={(event) =>
                setForm({ ...form, password: event.target.value })
              }
              placeholder="••••••••••••••"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Nova senha</FormLabel>
            <Input
              size="lg"
              type="password"
              value={form.newPassword}
              onChange={(event) =>
                setForm({ ...form, newPassword: event.target.value })
              }
              placeholder="••••••••••••••"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Repetir nova senha</FormLabel>
            <Input
              size="lg"
              type="password"
              value={form.newPasswordConfirm}
              onChange={(event) =>
                setForm({ ...form, newPasswordConfirm: event.target.value })
              }
              placeholder="••••••••••••••"
            />
          </FormControl>
        </VStack>
        <Flex direction="column" mt="1rem">
          <Button
            w="full"
            size="lg"
            fontSize="1rem"
            variant="solid"
            bg={theme.color.primary}
            color={theme.color.tertiary}
            _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
            onClick={changePassword}
          >
            Alterar senha
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}
