import { SearchParams, SearchResult } from "../../entities"
import { TransactionalLog } from "../../entities/csg/TransactionalLog"
import QueryStringBuilder from "../../util/queryBuilder"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class TransactionalLogRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<TransactionalLog>> {
    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter?.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/transactional-log?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const data = response.data.map(
      (notification: any) => new TransactionalLog(notification)
    )

    const result = new SearchResult<TransactionalLog>(data, response.info)

    return result
  }

  public static async readOne(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "POST",
      `/transactional-log/read-one/${id}`,
      {},
      headers
    )

    return response.data
  }

  public static async readAll(token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "POST",
      `/transactional-log/read-all`,
      {},
      headers
    )

    return response.data
  }

  public static async get(
    id: number,
    token: string
  ): Promise<TransactionalLog> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/transactional-log/${id}`,
      {},
      headers
    )

    return new TransactionalLog(response)
  }

  public static async delete(id: number, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "DELETE",
      `/transactional-log/${id}`,
      {},
      headers
    )

    return response === true
  }
}
