import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Button,
  Box,
  CloseButton,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react"
import React from "react"
import NotificationCard from "../notificationCard/NotificationCard"
import { TransactionalLog } from "../../entities/csg/TransactionalLog"
import theme from "../../Themes"
import { TransactionalLogService } from "../../services/csg/TransactionalLogService"

type NotificationDrawerProps = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  notifications: TransactionalLog[]
  hasNotification: boolean
  callback?: () => void
}

export function NotificationDrawer({
  isOpen,
  onClose,
  onOpen,
  hasNotification,
  notifications,
  callback,
}: NotificationDrawerProps) {
  const readAll = () => {
    TransactionalLogService.readAll().then(() => callback?.())
  }

  return (
    <>
      <Drawer isOpen={isOpen} size="full" placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Flex
              w="100%"
              h="100vh"
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              fontFamily="Raleway"
              gap="1rem"
              pt="2rem"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                maxW="600px"
              >
                <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
                  Notificações
                </Text>
                <Flex
                  w="44px"
                  h="44px"
                  p="10px"
                  bg="#E7ECFF"
                  borderRadius="50px"
                  justifyContent="center"
                  alignItems="center"
                  transition="0.3s ease-in-out"
                >
                  <CloseButton
                    onClick={onClose}
                    size="md"
                    color={theme.color.primary}
                    _hover={{ bg: "none", opacity: "0.8" }}
                  />
                </Flex>
              </Flex>

              {hasNotification ? (
                <>
                  <Flex
                    justifyContent="flex-start"
                    w="100%"
                    maxW="600px"
                    px="1rem"
                  >
                    <Button
                      fontSize="14px"
                      fontWeight="700"
                      bg="none"
                      color={theme.color.secondary}
                      transition="0.3s ease-in-out"
                      _hover={{ bg: "none", opacity: "0.8" }}
                      onClick={readAll}
                    >
                      Limpar notificações
                    </Button>
                  </Flex>
                  <Flex direction="column" w="100%" maxW="600px" px="1rem">
                    {notifications.map((log, index) => (
                      <React.Fragment key={log.id}>
                        <Box
                          borderLeft="none"
                          borderRight="none"
                          borderTop={index === 0 ? "1px solid #E2E8F0" : "none"}
                          borderBottom={
                            index === notifications.length - 1
                              ? "1px solid #E2E8F0"
                              : "1px solid #E2E8F0"
                          }
                          py="1rem"
                        >
                          <NotificationCard log={log} callback={callback} />
                        </Box>
                      </React.Fragment>
                    ))}
                  </Flex>
                </>
              ) : (
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  gap="1.5rem"
                  mt="2rem"
                >
                  <Image
                    src="/images/NotificationZIcon.png"
                    alt="Imagem de um sino de notificações"
                  />
                  <Text color="#4A5568" fontSize="16px" fontWeight="700">
                    Tudo tranquilo por enquanto.
                  </Text>
                  <Text color="#8E99AB" fontSize="14px" fontWeight="500">
                    Quando você receber notificações elas aparecerão aqui.
                  </Text>
                </Flex>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
