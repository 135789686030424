import { Button, Flex, Text } from "@chakra-ui/react"
import { PiTrashBold } from "react-icons/pi"
import { TransactionalLog } from "../../entities/csg/TransactionalLog"
import { useState } from "react"
import { TransactionalLogService } from "../../services/csg/TransactionalLogService"
import Loader from "../Loader/Loader"

interface NotificationProps {
  log: TransactionalLog
  callback?: () => void
}

export default function NotificationCard(props: NotificationProps) {
  const [isLoading, setIsLoading] = useState(false)

  const readOne = () => {
    setIsLoading(true)
    TransactionalLogService.readOne(props.log.id)
      .then(() => props.callback?.())
      .finally(() => setIsLoading(false))
  }

  return (
    <Flex w="100%" minH="76px" my="0.85rem">
      {isLoading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
      >
        <Flex direction="column" fontFamily="Raleway">
          <Text
            color="#2D3748"
            fontSize="12px"
            fontWeight="600"
            maxW="289.56px"
          >
            {props.log.subject}
          </Text>
          <Text
            color="#8E99AB"
            fontSize="12px"
            fontWeight="500"
            maxW="289.56px"
          >
            {props.log.data}
          </Text>
        </Flex>
        <Flex>
          <Button
            bg="none"
            color="#8E99AB"
            fontSize="18px"
            onClick={readOne}
            transition="0.3s ease-in-out"
            _hover={{ bg: "none", opacity: "0.8" }}
          >
            <PiTrashBold />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
