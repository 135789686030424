import React, { useEffect, useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Flex,
  Text,
  HStack,
  Button,
  PinInputField,
  PinInput,
  Link as ChakraLink,
  useToast,
} from "@chakra-ui/react"
import { RepeatIcon } from "@chakra-ui/icons"
import Layout from "../../../components/layout/Layout/Layout"
import UserStorage from "../../../util/userStorage"
import { AuthService } from "../../../services"
import theme from "../../../Themes"

export default function SmsVerificationCode() {
  const navigate = useNavigate()
  const toast = useToast()

  const [code, setCode] = useState("0000")

  useEffect(() => {
    sendValidationCode()
    // eslint-disable-next-line
  }, [])

  const sendValidationCode = () => {
    const id = UserStorage.get()?.id
    if (id) {
      AuthService.sendCode(id, "sms")
      toast({
        title: "Código enviado para o telefone",
        status: "success",
        duration: 1000,
      })
    }
  }

  const changeCode = (value: string, idx: number) => {
    const newCode = code.slice(0, idx) + value + code.slice(idx + value.length)
    setCode(newCode)
  }

  const save = async () => {
    const id = UserStorage.get()?.id
    const codeInt = parseInt(code)

    if (!id) {
      toast({
        title: "ID do usuário inválido",
        status: "error",
        duration: 1000,
      })
      return
    }

    AuthService.activateAccount(codeInt).then((success) => {
      if (success) {
        navigate("/cadastrar/sucesso")
      } else {
        toast({
          title: "Código inválido ou expirado.",
          status: "error",
          duration: 1000,
        })
        return
      }
    })
  }

  return (
    <Layout
      internal={false}
      header={{ title: "", backTo: "/", tip: "Dúvidas acesse nosso site" }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
      >
        <Flex direction="column">
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Estamos quase lá!
          </Text>
          <Text color="#4A5568" fontSize="0.875rem" fontWeight="400">
            Para garantir a segurança da sua conta, insira o código que enviamos
            para o telefone celular{" "}
            <Text color="#114B8F" fontSize="0.875rem" fontWeight="400">
              {UserStorage.get()?.phone}
            </Text>
          </Text>
        </Flex>
        <HStack justifyContent="center">
          <PinInput size="lg" type="number">
            <PinInputField
              onChange={(event) => changeCode(event.target.value, 0)}
            />
            <PinInputField
              onChange={(event) => changeCode(event.target.value, 1)}
            />
            <PinInputField
              onChange={(event) => changeCode(event.target.value, 2)}
            />
            <PinInputField
              onChange={(event) => changeCode(event.target.value, 3)}
            />
          </PinInput>
        </HStack>
        <Text
          color="#2D3748"
          fontSize="0.875rem"
          fontWeight="500"
          alignSelf="center"
          display="flex"
          gap="0.25rem"
        >
          Não recebi o código.{" "}
          <ChakraLink>
            <Text color="#3531C6" fontWeight="700" cursor="pointer">
              <RepeatIcon /> Reenviar
            </Text>
          </ChakraLink>
        </Text>
        {/* <ChakraLink w="100%" as={ReactRouterLink} to="/ativar-conta/email">
          <Text
            color="#3531C6"
            fontSize="0.875rem"
            fontWeight="700"
            textAlign="center"
          >
            Receber código via e-mail
          </Text>
        </ChakraLink> */}
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Button
          w="100%"
          size="lg"
          fontSize="1rem"
          bg={theme.color.primary}
          color={theme.color.tertiary}
          _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
          onClick={save}
        >
          Enviar
        </Button>
      </Flex>
    </Layout>
  )
}
