import React from "react"
import { Flex, Text, Image, Badge, Button } from "@chakra-ui/react"
import Formatter from "../../util/formatter"
import theme from "../../Themes"

interface ActivityHistoryProps {
  id: number
  icon: string
  title: string
  status: string
  statusCode: string
  value: string
  date: string
  dueDate: string
  subTitle: string
  arrow?: boolean
}

const getBadgeColor = (statusCode: string) => {
  if (statusCode === "paid") return { colorScheme: "green", color: "#38A169" }
  if (statusCode === "processing")
    return { colorScheme: "gray", color: "#8E99AB" }
  if (statusCode === "overdue") return { colorScheme: "red", color: "#C53030" }
  if (statusCode === "ontime") return { colorScheme: "blue", color: "#7694FF" }
  if (statusCode === "added") return { colorScheme: "green", color: "#38A169" }
  if (statusCode === "completed")
    return { colorScheme: "green", color: "#38A169" }

  return { colorScheme: "white", color: "#8E99AB" }
}

export default function ActivityHistoryCard(props: ActivityHistoryProps) {
  const badgeColor = getBadgeColor(props.statusCode)
  const arrow = props.arrow === true ? true : false

  return (
    <Flex
      w="full"
      alignItems="center"
      gap="2"
      justifyContent="space-between"
      fontFamily="Raleway"
    >
      <Flex gap="2" alignItems="center">
        <Flex
          bg="#E2E8F0"
          p="0.750rem"
          borderRadius="0.5rem"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src={props.icon}
            alt="Ícone do cartão do histórico de atividade"
          />
        </Flex>
        <Flex direction="column">
          <Text color="#2D3748" fontSize="0.75rem" fontWeight="600">
            {props.title}
          </Text>
          <Text color="#8E99AB" fontSize="0.75rem" fontWeight="400">
            {props.subTitle}
          </Text>
          <Text color="#8E99AB" fontSize="0.75rem" fontWeight="400">
            {props.date}
          </Text>
        </Flex>
      </Flex>

      <Flex gap="2" alignItems="center">
        <Flex direction="column">
          <Flex gap="2">
            <Badge
              variant="subtle"
              colorScheme={badgeColor.colorScheme}
              color={badgeColor.color}
              textTransform={{ _firstLetter: "capitalize" }}
            >
              {Formatter.formatStatus(props.status)}
            </Badge>
            <Text color="#2D3748" fontSize="0.75rem" fontWeight="700">
              R$ {props.value}
            </Text>
          </Flex>
          <Text
            color="#8E99AB"
            fontSize="0.75rem"
            fontWeight="400"
            alignSelf="flex-end"
          >
            Venc. {props.dueDate}
          </Text>
        </Flex>
        <Button p="0" bg={theme.color.tertiary} minW="0" height="0">
          {arrow && (
            <Image
              src="/images/card-icon/CaretRight.svg"
              alt="Ícone de redirecionamento"
            />
          )}
        </Button>
      </Flex>
    </Flex>
  )
}
