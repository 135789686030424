import React, { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react"
import Layout from "../../components/layout/Layout/Layout"
import { AuthService } from "../../services"
import theme from "../../Themes"

export default function RedefinePassword() {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const [form, setForm] = useState({
    newPassword: "",
    newPasswordConfirm: "",
  })

  const save = () => {
    if (form.newPassword.length < 6) {
      alert("A senha deve ter pelo menos 6 caracteres.")
      return
    }

    if (form.newPassword !== form.newPasswordConfirm) {
      alert("A nova senha e a confirmação estão diferentes")
      return
    }

    const email = searchParams.get("email") || ""
    const document = searchParams.get("document") || ""
    const code = parseInt(searchParams.get("code") || "0")
    AuthService.recoveryPassword(
      email,
      document,
      code,
      form.newPassword,
      form.newPasswordConfirm
    ).then((success) => {
      if (success) {
        navigate("/redefinir-senha/sucesso")
      }
    })
  }

  return (
    <Layout
      internal={false}
      header={{ title: "", backTo: "/", tip: "Dúvidas acesse nosso site" }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
      >
        <Flex direction="column">
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Redefinir senha
          </Text>
          <Text color="#4A5568" fontSize="0.875rem" fontWeight="400">
            Redefina a sua senha
          </Text>
        </Flex>
        <FormControl isRequired isInvalid={true}>
          <FormLabel>Senha</FormLabel>
          <Input
            size="lg"
            type="password"
            value={form.newPassword}
            onChange={(event) =>
              setForm({ ...form, newPassword: event.target.value })
            }
            placeholder="••••••••••••••"
          />
        </FormControl>
        <FormControl isRequired isInvalid={true}>
          <FormLabel>Repetir senha</FormLabel>
          <Input
            size="lg"
            type="password"
            value={form.newPasswordConfirm}
            onChange={(event) =>
              setForm({ ...form, newPasswordConfirm: event.target.value })
            }
            placeholder="••••••••••••••"
          />
        </FormControl>
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Button
          w="full"
          size="lg"
          fontSize="1rem"
          bg={theme.color.primary}
          color={theme.color.tertiary}
          _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
          onClick={save}
        >
          Redefinir senha
        </Button>
      </Flex>
    </Layout>
  )
}
