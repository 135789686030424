import React, { useEffect, useState } from "react"
import {
  Link as ReactRouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import {
  Flex,
  Text,
  HStack,
  Button,
  PinInputField,
  PinInput,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { RepeatIcon } from "@chakra-ui/icons"
import Layout from "../../components/layout/Layout/Layout"
import { AuthService } from "../../services"
import theme from "../../Themes"

export default function EmailVerification() {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [code, setCode] = useState("0000")

  const [param, setParam] = useState("")
  const [type, setType] = useState("")
  const [partial, setPartial] = useState("***")

  useEffect(() => {
    sendCode()
    // eslint-disable-next-line
  }, [])

  const sendCode = async () => {
    const email = searchParams.get("email") || ""
    const document = searchParams.get("document")

    if (email) {
      setParam(email)
      setType("email")
      setPartial(email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2"))
      let result: string = await AuthService.forgotPasswordWithEmail(
        email,
        "email"
      )
      setPartial(result)
    }

    if (document) {
      setParam(document)
      setType("document")
      let result: string = await AuthService.forgotPasswordWithDocument(
        document,
        "email"
      )
      setPartial(result)
    }
  }

  const save = () => {
    const email = searchParams.get("email") || ""
    const document = searchParams.get("document") || ""
    const newCode = parseInt(code)
    AuthService.isValidRecoveryPasswordCode(email, document, newCode).then(
      (success) => {
        if (success) {
          if (searchParams.get("email")) {
            navigate(`/redefinir-senha?email=${email}&code=${newCode}`)
          } else {
            navigate(`/redefinir-senha?document=${document}&code=${newCode}`)
          }
        } else {
          alert("Código inválido")
        }
      }
    )
  }

  const changeCode = (value: string, idx: number) => {
    const newCode = code.slice(0, idx) + value + code.slice(idx + value.length)
    setCode(newCode)
  }

  return (
    <Layout
      internal={false}
      header={{ title: "", backTo: "/", tip: "Dúvidas acesse nosso site" }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
      >
        <Flex direction="column">
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Verificação por e-mail
          </Text>
          <Text color="#4A5568" fontSize="0.875rem" fontWeight="400">
            Insira o código que enviamos para o e-mail{" "}
            <Text color="#114B8F" fontSize="0.875rem" fontWeight="400">
              {partial}
            </Text>
          </Text>
        </Flex>
        <HStack justifyContent="center">
          <PinInput size="lg" type="number">
            <PinInputField
              onChange={(event) => changeCode(event.target.value, 0)}
            />
            <PinInputField
              onChange={(event) => changeCode(event.target.value, 1)}
            />
            <PinInputField
              onChange={(event) => changeCode(event.target.value, 2)}
            />
            <PinInputField
              onChange={(event) => changeCode(event.target.value, 3)}
            />
          </PinInput>
        </HStack>
        <Text
          color="#2D3748"
          fontSize="0.875rem"
          fontWeight="500"
          alignSelf="center"
          display="flex"
          gap="0.25rem"
        >
          Não recebi o código.{" "}
          <ChakraLink>
            <Text
              color="#3531C6"
              fontWeight="700"
              cursor="pointer"
              onClick={sendCode}
            >
              <RepeatIcon /> Reenviar
            </Text>
          </ChakraLink>
        </Text>
        <ChakraLink
          w="100%"
          as={ReactRouterLink}
          to={`/verificar-sms?${type}=${param}`}
        >
          <Text
            color="#3531C6"
            fontSize="0.875rem"
            fontWeight="700"
            textAlign="center"
          >
            Receber código via SMS
          </Text>
        </ChakraLink>
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Button
          w="100%"
          size="lg"
          fontSize="1rem"
          bg={theme.color.primary}
          color={theme.color.tertiary}
          _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
          onClick={save}
        >
          Enviar
        </Button>
      </Flex>
    </Layout>
  )
}
