import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Flex,
  Text,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { MinusIcon, AddIcon } from "@chakra-ui/icons"
import theme from "../../Themes"

interface PaymentTypePixProps {
  pay: any
}

export default function PaymentTypePix(props: PaymentTypePixProps) {
  return (
    <Accordion
      allowToggle
      w="full"
      border="1px solid #E2E8F0"
      borderRadius="0.5rem"
      overflow="hidden"
      fontFamily="Raleway"
    >
      <AccordionItem bg={theme.color.tertiary}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton>
                <Flex
                  as="span"
                  flex="1"
                  textAlign="left"
                  alignItems="center"
                  gap="1rem"
                >
                  <Image src="/images/card-icon/Pix.svg" alt="Ícone do PIX" />
                  <Text fontSize="0.875rem" fontWeight="700" color="#171923">
                    Pagar via PIX
                  </Text>
                </Flex>
                {isExpanded ? (
                  <MinusIcon fontSize="0.875rem" />
                ) : (
                  <AddIcon fontSize="0.875rem" />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex w="full" h="full" direction="column" gap="1rem">
                <Text className="0.750" fontWeight="500" color="#4A5568">
                  A seguir, você encontrará o código PIX necessário para
                  concluir o pagamento. Basta copiá-lo e colá-lo em seu
                  aplicativo de pagamento.
                </Text>
                <Button
                  w="full"
                  size="md"
                  bg={theme.color.primary}
                  color={theme.color.tertiary}
                  p="0 1rem"
                  fontSize="0.875rem"
                  fontWeight="600"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="0.375rem"
                  onClick={() => props.pay()}
                >
                  Pagar com PIX
                </Button>
              </Flex>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
