export class TransactionalLog {
  type: string

  identifier: string

  subject: string

  template?: string

  id: string

  notification: string

  data?: any

  messageId?: string

  status: string

  createdAt: Date

  updatedAt: Date

  readAt?: Date

  constructor(data: any = {}) {
    this.type = data.type
    this.identifier = data.identifier
    this.subject = data.subject
    this.template = data.template
    this.id = data._id
    this.notification = data.notification
    this.data = data.data
    this.messageId = data.messageId
    this.status = data.status
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.readAt = data.readAt
  }
}
