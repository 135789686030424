import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import InputMask from "react-input-mask"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Divider,
  AbsoluteCenter,
} from "@chakra-ui/react"
import Layout from "../../components/layout/Layout/Layout"
import theme from "../../Themes"

export default function ForgotPassword() {
  const navigate = useNavigate()

  const [form, setForm] = useState({
    email: "",
    document: "",
  })

  const save = async () => {
    if (!form.email && !form.document) {
      alert("Digite um e-mail ou documento.")
      return
    }

    if (form.email) {
      navigate(`/verificar-email?email=${form.email}`)
    } else {
      navigate(`/verificar-email?document=${form.document}`)
    }
  }
  return (
    <Layout
      internal={false}
      header={{ title: "", backTo: "/", tip: "Dúvidas acesse nosso site" }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
      >
        <Flex direction="column">
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Esqueci minha senha
          </Text>
          <Text color="#4A5568" fontSize="0.875rem" fontWeight="400">
            Insira seu e-mail ou documento para recuperar sua senha
          </Text>
        </Flex>
        <FormControl isRequired isInvalid={true}>
          <FormLabel>E-mail</FormLabel>
          <Input
            size="lg"
            type="email"
            value={form.email}
            onChange={(event) =>
              setForm({ ...form, email: event.target.value })
            }
            placeholder="email@provedor.com"
          />
        </FormControl>
        <Box position="relative" padding="0.5rem">
          <Divider bg="#CFD9EA" />
          <AbsoluteCenter
            bg={theme.color.tertiary}
            px="1rem"
            color="#000000"
            fontSize="1rem"
            fontWeight="500"
          >
            ou
          </AbsoluteCenter>
        </Box>
        <FormControl isRequired isInvalid={true}>
          <FormLabel>Documento</FormLabel>
          <Input
            as={InputMask}
            mask="999.999.999-99"
            maskChar={null}
            size="lg"
            type="text"
            value={form.document}
            onChange={(event) =>
              setForm({ ...form, document: event.target.value })
            }
            placeholder="000.000.000-00"
          />
        </FormControl>
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Button
          w="100%"
          size="lg"
          fontSize="1rem"
          bg={theme.color.primary}
          color={theme.color.tertiary}
          _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
          onClick={save}
        >
          Enviar
        </Button>
      </Flex>
    </Layout>
  )
}
