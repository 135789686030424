import React, { useEffect } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Flex,
  Text,
  Button,
  Image,
  Divider,
  Link as ChakraLink,
  useToast,
  useDisclosure,
} from "@chakra-ui/react"
import Layout from "../../../components/layout/Layout/Layout"
import UserStorage from "../../../util/userStorage"
import { UserService } from "../../../services"
import { ContactService } from "../../../services/csg"
import DeleteAccountModal from "../../../components/deleteAccount/DeleteAccountModal"
import theme from "../../../Themes"

export default function UserProfile() {
  const navigate = useNavigate()
  const toast = useToast()

  const deleteAccountDisclosure = useDisclosure()

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
    })
    // eslint-disable-next-line
  }, [])

  const logout = () => {
    UserStorage.logout()
    navigate("/")
  }

  const requestAccountDeletion = () => {
    ContactService.requestDeleteAccount(UserStorage.get()?.email || "")
      .then((resp) => {
        toast({
          title: "Pedido de exclusão de conta enviado com sucesso.",
          status: "success",
          duration: 2000,
        })

        deleteAccountDisclosure.onClose()

        navigate(`/area-logada`)
      })
      .catch((error) => {
        console.log("error", error)
        toast({
          title: error?.response.data?.message,
          status: "error",
          duration: 2000,
        })
      })
  }

  return (
    <Layout internal={true} menuSelected={"profile"} valign="space-between">
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1.25rem"
        fontFamily="Raleway"
      >
        <Flex m="2rem 0 1rem 0" direction="column">
          <Text
            fontSize="1.5rem"
            fontWeight="700"
            color={theme.color.secondary}
            lineHeight="31.92px"
          >
            Olá,
          </Text>
          <Text
            fontSize="1.5rem"
            fontWeight="700"
            color={theme.color.primary}
            lineHeight="31.92px"
          >
            {UserStorage.get()?.firstname} {UserStorage.get()?.lastname}
          </Text>
        </Flex>
        <Flex direction="column">
          <Divider orientation="horizontal" mb="0.5rem" />
          <ChakraLink w="full" as={ReactRouterLink} to="/perfil/editar">
            <Button
              w="full"
              py="1.25rem"
              bg={theme.color.tertiary}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex gap="0.5rem" alignItems="center">
                <Image src="images/card-icon/User.svg" alt="Editar perfil" />
                <Text fontSize="1rem" fontWeight="700" color="#2D3748">
                  Editar Perfil
                </Text>
              </Flex>
              <Image
                src="images/card-icon/CaretRight.svg"
                alt="Seta para a direita"
              />
            </Button>
          </ChakraLink>
          <Divider orientation="horizontal" mt="0.5rem" mb="0.5rem" />
          <ChakraLink w="full" as={ReactRouterLink} to="/perfil/alterar-senha">
            <Button
              w="full"
              py="1.25rem"
              bg={theme.color.tertiary}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex gap="0.5rem" alignItems="center">
                <Image src="images/card-icon/Key.svg" alt="Alterar senha" />
                <Text fontSize="1rem" fontWeight="700" color="#2D3748">
                  Alterar senha
                </Text>
              </Flex>
              <Image
                src="images/card-icon/CaretRight.svg"
                alt="Seta para a direita"
              />
            </Button>
          </ChakraLink>
          <Divider orientation="horizontal" mt="0.5rem" mb="0.5rem" />
          <ChakraLink w="full" as={ReactRouterLink} to="/perfil/resgatar-saldo">
            <Button
              w="full"
              py="1.25rem"
              bg={theme.color.tertiary}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex gap="0.5rem" alignItems="center">
                <Image
                  src="images/card-icon/CoinsNotFill.svg"
                  alt="Resgatar saldo"
                />
                <Text fontSize="1rem" fontWeight="700" color="#2D3748">
                  Resgatar saldo
                </Text>
              </Flex>
              <Image
                src="images/card-icon/CaretRight.svg"
                alt="Seta para a direita"
              />
            </Button>
          </ChakraLink>
          <Divider orientation="horizontal" mt="0.5rem" mb="0.5rem" />
          <Button
            w="full"
            py="1.25rem"
            bg={theme.color.tertiary}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={logout}
          >
            <Flex gap="0.5rem" alignItems="center">
              <Image src="images/card-icon/SignOut.svg" alt="Sair" />
              <Text fontSize="1rem" fontWeight="700" color="#E53E3E">
                Sair
              </Text>
            </Flex>
          </Button>
          <Divider orientation="horizontal" mt="0.5rem" />
        </Flex>
        <Flex
          w="full"
          p="1.15rem 1rem"
          borderRadius="0.5rem"
          bg="#E2E8F03D"
          direction="column"
          gap="0.5rem"
        >
          <Text fontSize="1rem" fontWeight="700" color={theme.color.secondary}>
            {`Fale com a ${theme.company.name}`}
          </Text>
          <Flex direction="column">
            <Text fontSize="0.875rem" fontWeight="700" color="#2D3748">
              E-mail
            </Text>
            <Text fontSize="0.875rem" fontWeight="500" color="#2D3748">
              {`${theme.company.email}`}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize="0.875rem" fontWeight="700" color="#2D3748">
              Telefone
            </Text>
            <Text fontSize="0.875rem" fontWeight="500" color="#2D3748">
              {`${theme.company.phone}`}
            </Text>
          </Flex>
          <Button
            w="full"
            size="sm"
            variant="outline"
            borderRadius="0.375rem"
            bg="none"
            border="1px solid #CFD9EA"
            color="#1A202C"
            fontSize="0.875rem"
            fontWeight="600"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => navigate("/contato")}
          >
            Formulário de contato
          </Button>
        </Flex>
        <ChakraLink w="100%" onClick={deleteAccountDisclosure.onOpen}>
          <Button
            w="100%"
            size="lg"
            fontSize="1rem"
            fontWeight="700"
            border={`1px solid ${theme.color.secondary}`}
            borderRadius="0.375rem"
            background={theme.color.tertiary}
            color={theme.color.secondary}
            _hover={{ bg: `${theme.color.tertiary}` }}
            _active={{ bg: `${theme.color.tertiary}` }}
          >
            Excluir conta
          </Button>
        </ChakraLink>
      </Flex>
      <DeleteAccountModal
        title="Remover Conta"
        message="Deseja solicitar a remoção da sua conta?"
        isOpen={deleteAccountDisclosure.isOpen}
        onOpen={deleteAccountDisclosure.onOpen}
        onClose={deleteAccountDisclosure.onClose}
        action={requestAccountDeletion}
      />
    </Layout>
  )
}
