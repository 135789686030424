import React, { useState, useEffect } from "react"
import InputMask from "react-input-mask"
import {
  Link as ReactRouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import {
  Flex,
  Text,
  FormControl,
  Input,
  Button,
  HStack,
  Image,
  Divider,
  InputGroup,
  InputLeftAddon,
  Checkbox,
  Link as ChakraLink,
  useToast,
  useDisclosure,
} from "@chakra-ui/react"
import { PiCar } from "react-icons/pi"
import { FiArrowRight } from "react-icons/fi"
import LicensePlateCard from "../../components/licensePlateCard/LicensePlateCard"
import Layout from "../../components/layout/Layout/Layout"
import { ChargeService } from "../../services/csg"
import Loader from "../../components/Loader/Loader"
import MobileStorage from "../../util/mobileStorage"
import TermsModal from "../../components/document/TermsModal"
import UserStorage from "../../util/userStorage"
import theme from "../../Themes"

export default function ChargeSearch() {
  const navigate = useNavigate()
  const toast = useToast()

  const [licensePlate, setLicensePlate] = useState("")
  const [isChecked, setIsChecked] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [notFound, setNotFound] = useState(false)

  const [searchParams] = useSearchParams()

  const termsDisclosure = useDisclosure()
  const politicsDisclosue = useDisclosure()

  useEffect(() => {
    const licensePlate = searchParams.get("licensePlate")
    if (licensePlate) {
      search(licensePlate)
      setLicensePlate(licensePlate)
    } else {
      setIsChecked(false)
    }
  }, [])

  const search = async (licensePlate: string) => {
    if (licensePlate.length < 3) {
      toast({
        title: "Digite uma placa válida.",
        status: "warning",
      })
      return
    }

    if (!isChecked) {
      toast({
        title:
          "Você deve aceitar as políticas de privacidade e termos e condições de uso.",
        status: "warning",
      })
      return
    }

    setIsLoading(true)
    ChargeService.searchByPlate(licensePlate)
      .then((response) => {
        if (response.data.length > 0) {
          navigate(`/checkout?licensePlate=${licensePlate}`)
        } else {
          setNotFound(true)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        if (error?.response?.status === 403) {
          toast({
            title:
              "Seu usuário está bloqueado. Por favor entre em contato com a CSG para realizar o desbloqueio.",
            status: "error",
            isClosable: true,
            duration: 5000,
            position: "top",
          })

          UserStorage.logout()
        }
      })
      .finally(() => setIsLoading(false))
  }

  const isSearchButtonEnabled = () => {
    return licensePlate.length >= 8 && isChecked
  }

  useEffect(() => {
    setNotFound(false)
  }, [licensePlate])

  return (
    <Layout
      internal={false}
      header={{
        title: "Buscar veículo",
        backTo: !MobileStorage.isMobile()
          ? process.env.REACT_APP_SITE_URI || ""
          : "/",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      {isLoading && <Loader />}
      <Flex
        w="100%"
        direction="column"
        minHeight="80vh"
        justifyContent="space-between"
      >
        <Flex
          w="100%"
          maxWidth="515px"
          minH="500px"
          direction="column"
          gap="0.5rem"
          fontFamily="Raleway"
        >
          {notFound && (
            <>
              <LicensePlateCard licensePlate={licensePlate} />
              <Text
                fontSize="1.25rem"
                fontWeight="700"
                color="#2D3748"
                textAlign="center"
              >
                Não encontramos registros do veículo informado
              </Text>
              <Text
                fontSize="1rem"
                fontWeight="500"
                color="#8E99AB"
                textAlign="center"
              >
                Verifique se você digitou corretamente e tente buscar novamente
              </Text>
              <HStack
                bg="#F7F8F9"
                p="0.625rem 2rem 0.625rem 0.75rem"
                borderRadius="0.5rem"
                display="flex"
                gap="0.625rem"
                alignItems="flex-start"
              >
                <Image
                  src="/images/card-icon/Info.svg"
                  alt="Ícone de informação"
                />
                <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
                  As passagens podem demorar até 30 minutos para estarem
                  disponíveis para pagamento.
                </Text>
              </HStack>

              <HStack
                bg="#FFFAF0"
                p="0.625rem 2rem 0.625rem 0.75rem"
                borderRadius="0.5rem"
                display="flex"
                gap="0.625rem"
                alignItems="flex-start"
              >
                <Image
                  src="/images/card-icon/WarningDiamond.svg"
                  alt="Ícone de atenção"
                />
                <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
                  Atenção, para veículos comerciais articulados, caso não
                  encontre sua passagem, favor realizar também a consulta pela
                  placa traseira.
                </Text>
              </HStack>
              <Divider orientation="horizontal" mt={3} />
            </>
          )}
          {!notFound && (
            <>
              <Text fontSize="1.5rem" fontWeight="700" color="#2D3748">
                Informe a placa do seu veículo para localizar cobranças
              </Text>
              <Text fontSize="0.875rem" fontWeight="400" color="#8E99AB">
                Supervisionamos as rodovias
                <Text fontSize="0.875rem" fontWeight="700" color="#8E99AB">
                  ERS-122, ERS-446, RSC-287, RSC-453, BRS-470 e ERS 240
                </Text>
              </Text>
            </>
          )}
          <HStack flexDirection="column" mt={5}>
            <Text
              fontSize="0.875rem"
              fontWeight="700"
              color="#2D3748"
              alignSelf="flex-start"
            >
              Buscar veículo
            </Text>
            <FormControl isRequired display="flex">
              <InputGroup size="lg">
                <InputLeftAddon bg="none">
                  <PiCar />
                </InputLeftAddon>
                <Input
                  textTransform="uppercase"
                  maxLength={8}
                  variant="outline"
                  size="lg"
                  type="text"
                  value={licensePlate}
                  onChange={(event) =>
                    setLicensePlate(event.target.value.toUpperCase())
                  }
                  placeholder="Placa do veículo"
                />
              </InputGroup>
            </FormControl>
          </HStack>
          <Button
            mt={10}
            w="full"
            size="lg"
            variant="solid"
            bg={
              licensePlate.length < 8 || !isChecked
                ? "#E2E8F0"
                : `${theme.color.primary}`
            }
            color={
              licensePlate.length < 8 || !isChecked
                ? `${theme.color.primary}`
                : `${theme.color.tertiary}`
            }
            _hover={
              licensePlate.length < 8 || !isChecked
                ? { bg: "", color: "" }
                : { bg: "#004b84", color: `${theme.color.tertiary}` }
            }
            borderRadius="0.375rem"
            p="0rem, 1.5rem, 0rem, 1.5rem"
            fontSize="1rem"
            fontWeight="600"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
            onClick={() => search(licensePlate)}
            disabled={!isSearchButtonEnabled()}
          >
            <Text>Buscar débitos</Text>
            <FiArrowRight />
          </Button>
          <Checkbox
            mt={2}
            isChecked={isChecked}
            colorScheme="orange"
            color="#1A202C"
            fontSize="0.875rem"
            fontWeight="400"
            alignItems="baseline"
            onChange={() => setIsChecked(!isChecked)}
            lineHeight="2rem"
            size="lg"
          >
            <Text as="span" pl="0.5rem">
              Estou de acordo com a{" "}
              <Text
                color="#3531C6"
                fontWeight="700"
                as="span"
                cursor="pointer"
                onClick={politicsDisclosue.onOpen}
              >
                Política de Privacidade
              </Text>{" "}
              e{" "}
              <Text
                color="#3531C6"
                fontWeight="700"
                as="span"
                cursor="pointer"
                onClick={termsDisclosure.onOpen}
              >
                Termos e Condições de Uso
              </Text>
            </Text>
          </Checkbox>
        </Flex>
        <Flex
          mb="2rem"
          w="100%"
          maxWidth="515px"
          minH="100px"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Text color="#2D3748" fontWeight="500" fontSize="0.875rem">
            Eu tenho uma conta.{" "}
            <ChakraLink
              as={ReactRouterLink}
              to="/login"
              color="#3531C6"
              fontWeight="700"
              fontSize="0.875rem"
            >
              Fazer login
            </ChakraLink>
          </Text>
        </Flex>
      </Flex>
      <TermsModal
        title="Política de Privacidade e Proteção de dados"
        url="/pdf/politica-privacidade-protecao-dados.pdf"
        isOpen={politicsDisclosue.isOpen}
        onOpen={politicsDisclosue.onOpen}
        onClose={politicsDisclosue.onClose}
        type="politics"
      />
      <TermsModal
        title="Termo e Condição de Uso"
        url="/pdf/termo-condicao-uso-app.pdf"
        isOpen={termsDisclosure.isOpen}
        onOpen={termsDisclosure.onOpen}
        onClose={termsDisclosure.onClose}
        type="terms"
      />
    </Layout>
  )
}
