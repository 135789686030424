import React, { useState } from "react"
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import Layout from "../../components/layout/Layout/Layout"
import InputMask from "react-input-mask"
import { States } from "../../entities"
import TermsModal from "../../components/document/TermsModal"
import { ContactService } from "../../services/csg"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import theme from "../../Themes"

export default function ContactPage() {
  const toast = useToast()
  const navigate = useNavigate()

  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    message: "",
    checked: false,
  })

  const [isLoading, setIsLoading] = useState(false)

  const politicsDisclosue = useDisclosure()

  const save = () => {
    if (form.name.length <= 2) {
      toast({
        title: "Digite o nome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.phone.length < 15) {
      toast({
        title: "Digite o Telefone",
        status: "error",
        duration: 2000,
      })
      return
    }

    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)
    if (!isEmailValid) {
      toast({
        title: "Digite um e-mail válido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.city.length < 3) {
      toast({
        title: "Digite a Cidade.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.state.length !== 2) {
      toast({
        title: "Escolha o Estado.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.message.length <= 5) {
      toast({
        title: "Digite a mensagem.",
        status: "error",
        duration: 2000,
      })
      return
    }

    // if (!form.checked) {
    //   toast({
    //     title: "Você precisa ler e concordar com a política de privacidade.",
    //     status: "error",
    //     duration: 2000,
    //   })
    //   return
    // }

    setIsLoading(true)
    ContactService.contact(form)
      .then((user) => {
        toast({
          title: "Mensagem enviada com sucesso.",
          status: "success",
          duration: 2000,
        })

        navigate(`/area-logada`)
      })
      .catch((error) => {
        console.log("error", error)
        toast({
          title: error?.response.data?.message,
          status: "error",
          duration: 2000,
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Layout
      internal={true}
      menuSelected={"profile"}
      header={{
        title: "Fale Conosco",
        backTo: "/perfil",
      }}
      valign="space-between"
    >
      {isLoading && <Loader />}
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1.25rem"
        fontFamily="Raleway"
      >
        <Flex>
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Formulário de Contato
          </Text>
        </Flex>
        <FormControl isRequired>
          <FormLabel>Nome Completo</FormLabel>
          <Input
            textTransform="uppercase"
            size="lg"
            type="text"
            value={form.name}
            onChange={(event) => setForm({ ...form, name: event.target.value })}
            placeholder="Nome Completo"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Telefone</FormLabel>
          <Input
            as={InputMask}
            mask="(99) 99999-9999"
            maskChar={null}
            size="lg"
            type="text"
            value={form.phone}
            onChange={(event) =>
              setForm({ ...form, phone: event.target.value })
            }
            placeholder="(00) 00000-0000"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>E-mail</FormLabel>
          <Input
            size="lg"
            type="email"
            value={form.email}
            onChange={(event) =>
              setForm({ ...form, email: event.target.value })
            }
            placeholder="email@provedor.com"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Cidade</FormLabel>
          <Input
            size="lg"
            type="text"
            value={form.city}
            onChange={(event) => setForm({ ...form, city: event.target.value })}
            placeholder="Cidade"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Estado</FormLabel>
          <Select
            size="lg"
            placeholder="Estado"
            value={form.state}
            onChange={(event) =>
              setForm({ ...form, state: event.target.value })
            }
          >
            {States.map((item: any) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Mensagem</FormLabel>
          <Textarea
            placeholder="Digite sua mensagem"
            value={form.message}
            onChange={(event) =>
              setForm({ ...form, message: event.target.value })
            }
          />
        </FormControl>

        {/* <Checkbox
          checked={form.checked}
          onChange={(event) =>
            setForm({ ...form, checked: !form.checked })
          } 
        /> <Text as="span">Li e concordo com a</Text>{" "}
        <Text
        color="#3531C6"
        fontWeight="700"
        as="span"
        cursor="pointer"
        onClick={politicsDisclosue.onOpen}
      >
        Política de Privacidade
      </Text> */}

        <Button
          w="100%"
          size="lg"
          fontSize="1rem"
          bg={theme.color.primary}
          color={theme.color.tertiary}
          _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
          onClick={save}
        >
          Enviar Mensagem
        </Button>
      </Flex>
      <TermsModal
        title="Política de Privacidade e Proteção de dados"
        url="/pdf/politica-privacidade-protecao-dados.pdf"
        isOpen={politicsDisclosue.isOpen}
        onOpen={politicsDisclosue.onOpen}
        onClose={politicsDisclosue.onClose}
        type="politics"
      />
    </Layout>
  )
}
