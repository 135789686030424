import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Button,
  Flex,
  VStack,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  CloseButton,
  Box,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { VscSearch } from "react-icons/vsc"
import { SearchFilter, SearchParams, Vehicle } from "../../entities"
import { VehicleService } from "../../services/csg"
import { BiLoader } from "react-icons/bi"
import SimpleCheckboxCard from "../checkboxCard/SimpleCheckboxCard"
import theme from "../../Themes"
import VehicleSelectedStorage from "../../util/vehicleSelectedStore"

interface VehicleFilterDrawerProps {
  isOpen: boolean
  onClose: () => void
  callback: (plates: string[]) => void
}

export function VehicleFilterDrawer({
  isOpen,
  onClose,
  callback,
}: VehicleFilterDrawerProps) {
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [vehiclesSelected, setVehiclesSelected] = useState<Vehicle[]>([])

  const load = () => {
    const filters: SearchFilter[] = []

    filters.push({
      key: "isActive",
      value: true,
    })

    const params = new SearchParams(filters, 1, 9999)

    setIsLoading(true)
    VehicleService.search(params)
      .then((result) => {
        setVehicles(result.data)
      })
      .catch((error: any) => {
        console.log("error", error.message)
      })
      .finally(() => setIsLoading(false))
  }

  const filteredVehicles = vehicles.filter((vehicle) =>
    vehicle.licensePlate.toLowerCase().includes(search.toLowerCase())
  )

  const handleOnSelect = (vehicle: Vehicle) => {
    const itemAlreadyExists = vehiclesSelected.some((v) => v.id === vehicle.id)

    if (itemAlreadyExists) {
      const fitleredVehicles = vehiclesSelected.filter(
        (v) => v.id !== vehicle.id
      )
      setVehiclesSelected(fitleredVehicles)
      VehicleSelectedStorage.set(fitleredVehicles)
      VehicleSelectedStorage.remove(vehicle)
      return
    } else {
      setVehiclesSelected((prev) => [...prev, vehicle])
    }
  }
  const handleApplyFilter = () => {
    const plates = vehiclesSelected.map((vehicle) => vehicle.licensePlate)
    callback(plates)

    VehicleSelectedStorage.set(vehiclesSelected)
    onClose()
  }

  const handleAddAll = () => {
    setVehiclesSelected((prevState) => {
      const newVehicles = filteredVehicles.filter(
        (vehicle) => !prevState.some((v) => v.id === vehicle.id)
      )
      const updatedVehicles = [...prevState, ...newVehicles]
      VehicleSelectedStorage.set(updatedVehicles)
      return updatedVehicles
    })
  }

  const handleClearAll = () => {
    setVehiclesSelected([])
    VehicleSelectedStorage.clear()
  }

  useEffect(() => {
    const selectedVehicles = VehicleSelectedStorage.get()
    setVehiclesSelected(selectedVehicles || [])
  }, [isOpen])

  useEffect(() => {
    load()
  }, [])

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody>
          <Flex
            w="100%"
            h="100vh"
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            fontFamily="Raleway"
            gap="1rem"
            pt="2rem"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              maxW="600px"
            >
              <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
                Filtrar cobranças por veículo
              </Text>
              <Flex
                w="44px"
                h="44px"
                p="10px"
                bg="#E7ECFF"
                borderRadius="50px"
                justifyContent="center"
                alignItems="center"
                transition="0.3s ease-in-out"
              >
                <CloseButton
                  onClick={onClose}
                  size="md"
                  color={theme.color.primary}
                  _hover={{ bg: "none", opacity: "0.8" }}
                />
              </Flex>
            </Flex>

            <Flex
              w="100%"
              h="100%"
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              fontFamily="Raleway"
              pt="2rem"
              px="1rem"
              gap="1.5rem"
            >
              <Flex
                w="100%"
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <InputGroup size="lg" maxW="600px">
                  <InputLeftAddon bg="none">
                    <VscSearch />
                  </InputLeftAddon>
                  <Input
                    maxLength={8}
                    variant="outline"
                    size="lg"
                    type="text"
                    placeholder="Buscar veículo"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </InputGroup>

                <Flex w="100%" maxW="600px" justifyContent="space-between">
                  <Button
                    fontSize="14px"
                    fontWeight="600"
                    p="0"
                    bg="none"
                    color="#3531C6"
                    transition="0.3s ease-in-out"
                    _hover={{ bg: "none", opacity: "0.8" }}
                    onClick={handleAddAll}
                  >
                    Adicionar Tudo
                  </Button>

                  <Button
                    fontSize="14px"
                    fontWeight="600"
                    p="0"
                    bg="none"
                    color="#3531C6"
                    transition="0.3s ease-in-out"
                    _hover={{ bg: "none", opacity: "0.8" }}
                    onClick={handleClearAll}
                  >
                    Limpar tudo
                  </Button>
                </Flex>
              </Flex>

              {isLoading ? (
                <BiLoader size="2rem" />
              ) : filteredVehicles.length > 0 ? (
                <VStack w="100%" maxW="600px">
                  {filteredVehicles.map((vehicle) => (
                    <SimpleCheckboxCard
                      key={vehicle.id}
                      vehicle={vehicle}
                      onChange={handleOnSelect}
                      isChecked={vehiclesSelected.some(
                        (v) => v.id === vehicle.id
                      )}
                    />
                  ))}
                </VStack>
              ) : (
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  gap="1.5rem"
                  mt="2rem"
                >
                  <Text color="#4A5568" fontSize="16px" fontWeight="700">
                    Nenhum veículo encontrado.
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>

          <Box
            alignItems="center"
            position="fixed"
            bottom="0"
            w="100%"
            bg={theme.color.tertiary}
            py="1rem"
            px="1rem"
            display="flex"
            justifyContent="center"
          >
            <Button
              w="100%"
              onClick={handleApplyFilter}
              maxW="600px"
              size="lg"
              bg={theme.color.primary}
              color={theme.color.tertiary}
              _hover={{ bg: theme.color.quaternary, transition: "0.3s" }}
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
            >
              Aplicar
            </Button>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
