import React, { useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Link as ChakraLink,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react"
import { PiEyeFill } from "react-icons/pi"
import { IoCloseOutline } from "react-icons/io5"
import Layout from "../../components/layout/Layout/Layout"
import { AuthService } from "../../services/auth/AuthService"
import UserStorage from "../../util/userStorage"
import OneSignalStorage from "../../util/oneSignalStorage"
import theme from "../../Themes"

export default function Login() {
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const isEmailError = email === ""
  const isPasswordError = password === ""

  const handleEmailChange = (event: any) => setEmail(event.target.value)
  const handlePasswordChange = (event: any) => setPassword(event.target.value)
  // const handleRememberPasswordChange = () =>
  //   setRememberPassword(!rememberPassword)

  const login = () => {
    const oneSignal = OneSignalStorage.get() || ""
    AuthService.login(email, password, oneSignal)
      .then(() => {
        if (UserStorage.get()?.isActive === true) navigate("/area-logada")
        else navigate("/ativar-conta/sms")
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const [isPasswordWrong, setIsPasswordWrong] = useState(false)

  return (
    <Layout
      internal={false}
      header={{ title: "", backTo: "/", tip: "Dúvidas acesse nosso site" }}
      valign="space-between"
    >
      <Flex w="100%" maxWidth="515px" direction="column" gap="1rem">
        <Flex direction="column">
          <Text
            color={theme.color.secondary}
            fontSize="1.5rem"
            fontWeight="700"
            lineHeight="31.92px"
          >
            Olá,
          </Text>
          <Text
            color={theme.color.primary}
            fontSize="1.5rem"
            fontWeight="700"
            lineHeight="31.92px"
          >
            Bem-vindo de volta!
          </Text>
        </Flex>
        <FormControl isRequired isInvalid={isEmailError}>
          <FormLabel>E-mail</FormLabel>
          <Input
            size="lg"
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="email@provedor.com"
          />
          {isEmailError && (
            <FormErrorMessage>E-mail é necessário.</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={isPasswordError}>
          <FormLabel>Senha</FormLabel>
          <InputGroup>
            <Input
              size="lg"
              type={show ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Digite sua senha"
            />
            <InputRightElement h="100%" w="3rem">
              <Button
                h="100%"
                size="sm"
                onClick={handleClick}
                bg="transparent"
                color="#2D3748"
                transition="0.2s ease-in-out"
                _hover={{ bg: "transparent", color: `${theme.color.primary}` }}
                fontSize="1rem"
              >
                <PiEyeFill />
              </Button>
            </InputRightElement>
          </InputGroup>
          {isPasswordError && (
            <FormErrorMessage>Senha é necessária.</FormErrorMessage>
          )}
          {isPasswordWrong && (
            <FormErrorMessage display="flex" alignItems="center" gap="0.15rem">
              <Flex alignItems="center" fontSize="1.25rem">
                <IoCloseOutline />
              </Flex>
              Senha incorreta
            </FormErrorMessage>
          )}
        </FormControl>
        {/* <Checkbox
          defaultChecked={rememberPassword}
          colorScheme="facebook"
          display="flex"
          fontSize="0.875rem"
          fontWeight="400"
          color="#1A202C"
          onChange={handleRememberPasswordChange}
        >
          Lembrar minha senha
        </Checkbox> */}
        <Button
          w="100%"
          size="lg"
          fontSize="1rem"
          bg={theme.color.primary}
          color={theme.color.tertiary}
          _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
          onClick={login}
        >
          Fazer login
        </Button>
        <ChakraLink
          as={ReactRouterLink}
          to="/esqueci-minha-senha"
          fontSize="0.875rem"
          color="#3531C6"
          fontWeight="700"
          textAlign="center"
        >
          Esqueci minha senha
        </ChakraLink>
        <ChakraLink
          as={ReactRouterLink}
          to="/busca"
          fontSize="0.875rem"
          color="#3531C6"
          fontWeight="700"
          textAlign="center"
        >
          Fazer busca anônima
        </ChakraLink>
      </Flex>
      <Flex minH="100px" m="0 auto" alignItems="flex-end">
        <Text fontSize="0.875rem" color="#2D3748">
          Não tem uma conta?
          <ChakraLink
            as={ReactRouterLink}
            to="/cadastrar"
            color="#3531C6"
            fontWeight="700"
            ml="0.25rem"
          >
            Cadastrar
          </ChakraLink>
        </Text>
      </Flex>
    </Layout>
  )
}
