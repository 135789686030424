const theme = {
  color: {
    primary: process.env.REACT_APP_THEME_COLOR_PRIMARY || "#00335B",
    secondary: process.env.REACT_APP_THEME_COLOR_SECONDARY || "#F26522",
    tertiary: process.env.REACT_APP_THEME_COLOR_TERTIARY || "#ffffff",
    quaternary: process.env.REACT_APP_THEME_COLOR_QUATERNARY || "#003f70",
    quinary: process.env.REACT_APP_THEME_COLOR_QUINARY || "#17282E",
  },
  banner: {
    url:
      process.env.REACT_APP_THEME_BANNER_URL ||
      "/images/banner/csg-big-banner.webp",
  },
  bannerMobile: {
    url:
      process.env.REACT_APP_THEME_BANNER_MOBILE_URL ||
      "/images/banner/csg-small-banner.webp",
  },
  logoWhite: {
    url:
      process.env.REACT_APP_THEME_LOGO_WHITE_URL ||
      "/images/logo/csg-logo-white.png",
  },
  logoBlue: {
    url:
      process.env.REACT_APP_THEME_LOGO_BLUE_URL ||
      "/images/logo/csg-logo-blue.png",
  },
  logoOrange: {
    url:
      process.env.REACT_APP_THEME_LOGO_ORANGE_URL ||
      "/images/logo/csg-logo-orange.png",
  },
  logoBgBlack: {
    url:
      process.env.REACT_APP_THEME_LOGO_BG_BLACK_URL ||
      "/images/logo/csg-logo-bg-black.png",
  },
  logoTagline: {
    url:
      process.env.REACT_APP_THEME_LOGO_TAGLINE_URL ||
      "/images/logo/csg-logo-white-no-tagline.png",
  },

  company: {
    name: process.env.REACT_APP_THEME_COMPANY_NAME || "CSG",
    email: process.env.REACT_APP_THEME_COMPANY_EMAIL || "cac@csg.com.br",
    phone: process.env.REACT_APP_THEME_COMPANY_PHONE || "0800 122 0240",
  },
}

export default theme
