import React, { useCallback, useEffect, useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Container,
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  Image,
  Tag,
  Divider,
  Box,
  Link as ChakraLink,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from "@chakra-ui/react"
import { PiCar, PiCoinsFill, PiPlusBold } from "react-icons/pi"
import ActivityHistoryCard from "../../components/activityHistoryCard/ActivityHistoryCard"
import "./LoggedArea.scss"
import FooterMenu from "../../components/footerMenu/FooterMenu"
import {
  BankAccount,
  Charge,
  SearchFilter,
  SearchParams,
  Vehicle,
} from "../../entities"
import { ChargeService, VehicleService } from "../../services/csg"
import { UserService } from "../../services"
import { BankAccountService } from "../../services/csg/BankAccount"
import Formatter from "../../util/formatter"
import Loader from "../../components/Loader/Loader"
import UserStorage from "../../util/userStorage"
import OneSignalStorage from "../../util/oneSignalStorage"
import { VscBell, VscBellDot, VscFilterFilled } from "react-icons/vsc"
import theme from "../../Themes"
import { TransactionalLogService } from "../../services/csg/TransactionalLogService"
import { TransactionalLog } from "../../entities/csg/TransactionalLog"
import { NotificationDrawer } from "../../components/notification/notification-drawer"
import { VehicleFilterDrawer } from "../../components/vehicle/vehicle-drawer"

export default function LoggedArea() {
  const navigate = useNavigate()
  const toast = useToast()
  const user = UserStorage.get()

  const notificaitonDisclosure = useDisclosure()

  const [hasNotification, setHasNotification] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [bankAccount, setBankAccount] = useState<BankAccount>(new BankAccount())
  const [charges, setCharges] = useState<Charge[]>([])
  const [selectedCharges, setSelectedCharges] = useState<Charge[]>([])
  const [statusFilter, setStatusFilter] = useState<string | null>(null)

  const [logs, setLogs] = useState<TransactionalLog[]>([])

  const vehiclesDisclosue = useDisclosure()
  const drawerVehiclesDisclosue = useDisclosure()

  const loadNotifications = () => {
    const filters: SearchFilter[] = []

    filters.push({ key: "type", value: "push" })
    filters.push({ key: "identifier", value: user?.id })
    filters.push({ key: "status", value: "sent" })

    setIsLoading(true)
    TransactionalLogService.search(new SearchParams(filters, 1, 9999))
      .then((result) => {
        setLogs(result.data)

        if (result.data.length > 0) {
          setHasNotification(true)
        } else {
          setHasNotification(false)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleSelectVehicle = useCallback((plates: string[]) => {
    if (plates) {
      loadChargesByPlates(plates)
    } else {
      loadVeihicles()
      loadBankAccount()
    }
  }, [])

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
      loadVeihicles()
      loadBankAccount()
      updateOneSignalToken()
      loadNotifications()
    })
    // eslint-disable-next-line
  }, [])

  const loadVeihicles = () => {
    setIsLoading(true)
    const filter = new SearchFilter({ key: "isActive", value: true })
    const params = new SearchParams([filter], 1, 999_999)
    VehicleService.search(params)
      .then((result) => {
        const vehicles = result.data.filter(
          (vehicle) => vehicle.isActive === true && vehicle.isRegular === true
        )
        setVehicles(vehicles)

        if (vehicles.length === 0) {
          vehiclesDisclosue.onOpen()
        }

        loadCharges(vehicles.map((vehicle) => vehicle.licensePlate))
      })
      .catch((error) => {
        setIsLoading(false)
        if (error?.response?.status === 403) {
          toast({
            title:
              "Seu usuário está bloqueado. Por favor entre em contato com a CSG para realizar o desbloqueio.",
            status: "error",
            isClosable: true,
            duration: 5000,
            position: "top",
          })

          UserStorage.logout()
          navigate("/login")
        }
      })
  }

  const loadBankAccount = () => {
    BankAccountService.getByUser()
      .then((bankAccount) => setBankAccount(bankAccount))
      .catch((error) => console.log(error))
  }

  const loadCharges = (licensePlates: string[]) => {
    ChargeService.searchByPlates(licensePlates, null)
      .then((result) => {
        setCharges(result.data)
        setSelectedCharges(result.data)
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const loadChargesByPlates = (licencePlates: string[]) => {
    setIsLoading(true)
    ChargeService.searchByPlates(licencePlates)
      .then((result) => {
        setCharges(result.data)
        setSelectedCharges(result.data)
      })
      .finally(() => setIsLoading(false))
  }

  const updateOneSignalToken = () => {
    const token = OneSignalStorage.get()
    console.log("atualiza-token", token)
    if (token) {
      UserService.updateOneSignalToken(token)
    }
  }

  return (
    <Flex
      w="100%"
      maxWidth="515px"
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      m="0 auto"
    >
      {isLoading && <Loader />}
      <Flex w="100%" direction="column">
        <Flex w="100%" bg={theme.color.primary} p="1rem">
          <Flex w="100%" fontFamily="Raleway" direction="column">
            <Flex
              w="100%"
              mt="1.5rem"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex>
                <Image
                  src={theme.logoTagline.url}
                  alt="Versão branca do Logotipo da empresa"
                />
              </Flex>
              <Flex>
                <NotificationDrawer
                  hasNotification={hasNotification}
                  notifications={logs}
                  callback={loadNotifications}
                  isOpen={notificaitonDisclosure.isOpen}
                  onClose={notificaitonDisclosure.onClose}
                  onOpen={notificaitonDisclosure.onOpen}
                />

                <ChakraLink
                  onClick={notificaitonDisclosure.onOpen}
                  as={ReactRouterLink}
                  color={theme.color.tertiary}
                  bg="none"
                  fontSize="1.75rem"
                  transition="0.2s ease-in-out"
                  _hover={{ opacity: "0.8", bg: "none" }}
                >
                  {hasNotification && <VscBellDot />}
                  {!hasNotification && <VscBell />}
                </ChakraLink>
              </Flex>
            </Flex>
            <Flex w="100%" fontFamily="Raleway" direction="column" px="1.25rem">
              <Flex mt="1rem">
                <Text
                  color={theme.color.tertiary}
                  fontSize="1rem"
                  fontWeight="700"
                >
                  Seu saldo
                </Text>
              </Flex>
              <HStack gap="0.625rem">
                <Flex
                  bg="#E7ECFF14"
                  p="0.75rem"
                  borderRadius="4.375rem"
                  color={theme.color.tertiary}
                  fontSize="1.5rem"
                >
                  {/* <Image
                    src="/images/card-icon/Coins.svg"
                    alt="Ícone do saldo"
                  /> */}
                  <PiCoinsFill />
                </Flex>
                <Text
                  color={theme.color.tertiary}
                  fontSize="3rem"
                  fontWeight="700"
                >
                  {Formatter.formatCurrency(bankAccount.balance)}
                </Text>
              </HStack>
              <ChakraLink w="100%" as={ReactRouterLink} to="/adicionar-saldo">
                <Button
                  size="lg"
                  w="100%"
                  bg="#00D2A0"
                  color={theme.color.tertiary}
                  fontSize="0.875rem"
                  fontWeight="600"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="2"
                >
                  <PiPlusBold />
                  Adicionar saldo
                </Button>
              </ChakraLink>
              <HStack
                bg="#E7ECFF0A"
                my="1.25rem"
                p="0.5rem 0.9375rem"
                borderRadius="0.375rem"
                gap="0.625rem"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  src="/images/StarBlueIcon.png"
                  alt="Ícone da varinha mágica"
                />
                <Text color="#CFD9EA" fontSize="0.75rem" fontWeight="500">
                  Cobranças são pagas automaticamente com saldo disponível; sem
                  saldo, o pagamento é manual.
                </Text>
              </HStack>
            </Flex>
          </Flex>
        </Flex>

        <VStack
          w="100%"
          p="1rem"
          flex-shrink="0"
          bg={theme.color.tertiary}
          flexDirection="column"
          gap="3"
        >
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text color={theme.color.quinary} fontSize="1rem" fontWeight="700">
              Histórico
            </Text>
            <Button
              as={ReactRouterLink}
              onClick={drawerVehiclesDisclosue.onOpen}
              bg="none"
              color="#3531C6"
              fontSize="0.875rem"
              fontWeight="600"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="0.25rem"
              transition="0.3s ease-in-out"
              _hover={{ bg: "none", opacity: "0.8" }}
            >
              Filtrar <VscFilterFilled />
            </Button>
          </Flex>

          <VehicleFilterDrawer
            callback={handleSelectVehicle}
            isOpen={drawerVehiclesDisclosue.isOpen}
            onClose={drawerVehiclesDisclosue.onClose}
          />

          <Flex
            alignSelf="flex-start"
            w="auto"
            p="0.25rem 0.5rem"
            justifyContent="space-between"
            alignItems="center"
            border="1px solid"
            borderColor="#E2E8F0"
            borderRadius="0.375rem"
          >
            <Flex gap="2">
              <Flex
                maxW="22px"
                maxH="22px"
                bg="#E7ECFF"
                p="0.1875rem"
                borderRadius="0.5rem"
                alignItems="center"
                fontSize="0.75rem"
                color="#3531C6"
              >
                <PiCar />
              </Flex>
              <Flex
                direction="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Text
                  fontSize="0.75rem"
                  fontWeight="600"
                  color="#4A5568"
                  fontFamily="Raleway"
                >
                  Todos os meus veículos
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Box w="100%" h="1px" bg="#E2E8F0" my="0.5rem"></Box>
          <HStack w="full" display="flex" justifyContent="space-between">
            <Tag
              display="flex"
              padding="0 0.75rem"
              alignItems="center"
              justifyContent="center"
              gap="0.5rem"
              border={
                statusFilter === null
                  ? `${theme.color.secondary}`
                  : "1px solid #8E99AB"
              }
              borderRadius="0.375rem"
              size="md"
              variant="solid"
              bg={
                statusFilter === null
                  ? `${theme.color.secondary}`
                  : `${theme.color.tertiary}`
              }
              fontSize="0.875rem"
              fontWeight="500"
              color={
                statusFilter === null ? `${theme.color.tertiary}` : "#4A5568"
              }
              cursor="pointer"
              onClick={() => setStatusFilter(null)}
            >
              Tudo
            </Tag>
            <Tag
              display="flex"
              padding="0 0.75rem"
              alignItems="center"
              justifyContent="center"
              gap="0.5rem"
              border={
                statusFilter === "open"
                  ? `${theme.color.secondary}`
                  : "1px solid #8E99AB"
              }
              borderRadius="0.375rem"
              size="md"
              variant="solid"
              colorScheme="gray"
              bg={
                statusFilter === "open"
                  ? `${theme.color.secondary}`
                  : `${theme.color.tertiary}`
              }
              fontSize="0.875rem"
              fontWeight="500"
              color={
                statusFilter === "open" ? `${theme.color.tertiary}` : "#4A5568"
              }
              cursor="pointer"
              onClick={() => setStatusFilter("open")}
            >
              Pendentes
            </Tag>
            <Tag
              display="flex"
              padding="0 0.75rem"
              alignItems="center"
              justifyContent="center"
              gap="0.5rem"
              border={
                statusFilter === "paid"
                  ? `${theme.color.secondary}`
                  : "1px solid #8E99AB"
              }
              borderRadius="0.375rem"
              size="md"
              variant="solid"
              colorScheme="gray"
              bg={
                statusFilter === "paid"
                  ? `${theme.color.secondary}`
                  : `${theme.color.tertiary}`
              }
              fontSize="0.875rem"
              fontWeight="500"
              color={
                statusFilter === "paid" ? `${theme.color.tertiary}` : "#4A5568"
              }
              cursor="pointer"
              onClick={() => setStatusFilter("paid")}
            >
              Pagos
            </Tag>
          </HStack>
          {charges.filter((charge) => charge.status === "open").length > 0 && (
            <Flex
              w="100%"
              p="1.13rem 1.25rem"
              bg="#E7ECFFB2"
              borderRadius="0.375rem"
              direction="column"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="0.750rem"
              >
                <Flex alignItems="center" gap="0.5rem">
                  <Image
                    src="/images/RedWarning.png"
                    alt="Ícone de aviso de atenção"
                  />
                  <Text
                    color="#2D3748"
                    fontWeight="700"
                    fontSize="0.875rem"
                    maxW="165px"
                  >
                    Você possui{" "}
                    {
                      charges.filter((charge) => charge.status === "open")
                        .length
                    }{" "}
                    cobranças em aberto
                  </Text>
                </Flex>
                <Text color="#2D3748" fontWeight="700" fontSize="1.25rem">
                  {Formatter.formatCurrency(
                    charges
                      .filter((charge) => charge.status === "open")
                      .reduce(
                        (sum, charge: Charge) =>
                          sum +
                          (charge.rateAmount + charge.fee - charge.discount),
                        0
                      )
                  )}
                </Text>
              </Flex>
              <Flex w="100%">
                <ChakraLink w="100%" as={ReactRouterLink} to="/checkout">
                  <Button
                    w="100%"
                    size="md"
                    fontSize="0.875rem"
                    variant="solid"
                    bg={theme.color.primary}
                    color={theme.color.tertiary}
                    _hover={{
                      bg: `${theme.color.quaternary}`,
                      transition: "0.3s",
                    }}
                  >
                    Pagar agora
                  </Button>
                </ChakraLink>
              </Flex>
            </Flex>
          )}

          <HStack
            bg="#F7F8F9"
            p="0.625rem 2rem 0.625rem 0.75rem"
            borderRadius="0.5rem"
            display="flex"
            gap="0.625rem"
            alignItems="flex-start"
          >
            <Image src="/images/card-icon/Info.svg" alt="Ícone de informação" />
            <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
              As passagens podem demorar até 30 minutos para estarem disponíveis
              para pagamento.
            </Text>
          </HStack>
          <Divider orientation="horizontal" />
          <Flex w="full" direction="column" gap="2">
            {selectedCharges
              .filter((charge) =>
                statusFilter === null ? charge : charge.status === statusFilter
              )
              .map((charge) => (
                <>
                  <ActivityHistoryCard
                    id={charge.id}
                    icon="/images/card-icon/HourglassHigh.svg"
                    title={charge.licensePlate}
                    status={charge.status}
                    statusCode={charge.status}
                    value={Formatter.formatChargeTotal(charge)}
                    date={Formatter.formatDateTime(charge.datetimeOccurrence)}
                    dueDate={Formatter.formatDate(charge.dueDate)}
                    subTitle={charge.plazaDescription}
                    arrow={false}
                  />
                  <Divider orientation="horizontal" />
                </>
              ))}
          </Flex>
        </VStack>
      </Flex>
      <Modal
        isOpen={vehiclesDisclosue.isOpen}
        onClose={() => navigate("/veiculo/form")}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastre seus Veículos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Você não tem veículo cadastrado.
            <Flex alignContent="center" marginTop="20px">
              <Button margin="auto" onClick={() => navigate("/veiculo/form")}>
                Cadastre Agora
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <FooterMenu />
    </Flex>
  )
}
