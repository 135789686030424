import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { Flex, Text, Button, Image, Link as ChakraLink } from "@chakra-ui/react"
import Layout from "../../../components/layout/Layout/Layout"
import theme from "../../../Themes"

export default function UserFormSuccess() {
  return (
    <Layout
      internal={false}
      header={{
        title: "",
        backTo: "/area-logada",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        justifyContent="center"
        gap="1rem"
        m="auto 0"
      >
        <Flex justifyContent="center" alignItems="center">
          <Image
            src="/images/RegisterSuccessIcon.png"
            alt="Conta criada com sucesso"
          />
        </Flex>
        <Flex direction="column" alignItems="center">
          <Text
            textAlign="center"
            color="#2D3748"
            fontSize="1.5rem"
            fontWeight="700"
          >
            Sua conta foi criada
          </Text>
          <Text
            textAlign="center"
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="400"
          >
            Agora cadastre os seus veículos para ter acesso ao histórico de
            cobranças detalhado
          </Text>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <ChakraLink w="full" as={ReactRouterLink} to="/veiculo/form">
          <Button
            w="full"
            size="lg"
            fontSize="1rem"
            bg={theme.color.primary}
            color={theme.color.tertiary}
            _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
          >
            Cadastrar veículos
          </Button>
        </ChakraLink>
      </Flex>
    </Layout>
  )
}
