import { User } from "../entities"
import { decodeJwt } from "jose"

const storageKey = "@user"
class UserStorage {
  static hasToken = (): boolean => {
    var token = localStorage.getItem(storageKey)
    return !!token
  }

  static getToken = (): string => {
    const token = localStorage.getItem(storageKey)
    return token || ""
  }

  static get = (): User | null => {
    const token = UserStorage.getToken()
    if (!token) return null

    const data: any = decodeJwt(token)
    return data ? new User(data.user) : null
  }

  static setToken = (userToken: string) => {
    localStorage.setItem(storageKey, userToken)
  }

  static isTokenExpired = () => {
    const token = UserStorage.getToken()
    if (!token) return true

    const data: any = decodeJwt(token)

    const now = Math.floor(Date.now() / 1000)
    return now > data.exp
  }

  static logout = () => {
    localStorage.removeItem(storageKey)
  }
}

export default UserStorage
