import React from "react"
import { BrowserRouter } from "react-router-dom"
import Router from "./pages/Router"
import { ChakraProvider } from "@chakra-ui/react"
import "./App.scss"
import theme from "./Themes"

export default function App() {
  const root = document.documentElement
  root.style.setProperty("--color-primary", theme.color.primary)
  root.style.setProperty("--color-secondary", theme.color.secondary)
  root.style.setProperty("--color-tertiary", theme.color.tertiary)
  root.style.setProperty("--color-quaternary", theme.color.quaternary)

  return (
    <BrowserRouter>
      <div className="App">
        <ChakraProvider>
          <Router />
        </ChakraProvider>
      </div>
    </BrowserRouter>
  )
}
