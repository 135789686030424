import { Charge } from "../entities"

export default class Formatter {
  public static formatCurrency(value: number) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value / 100)
  }

  public static formatDate(date: Date): string {
    if (typeof date === "string") return date
    return date?.toLocaleDateString("pt-br") || ""
  }

  public static formatDateTime(date: Date): string {
    return (
      date?.toLocaleDateString("pt-br") +
        " " +
        date.toLocaleTimeString("pt-br") || ""
    )
  }

  public static formatStatus(status: string): string {
    if (status === "open") return "Aberto"
    if (status === "pending") return "Pendente"
    if (status === "paid") return "Pago"
    if (status === "complete") return "Finalizado"
    if (status === "completed") return "Finalizado"
    if (status === "free") return "Gratuito"
    return status
  }

  public static formatChargeTotal(charge: Charge) {
    const total = charge.rateAmount + charge.fee - charge.discount
    return Formatter.formatCurrency(total)
  }

  public static formatPartialDocument(document: string) {
    let partialDocument = document.replace(document.substring(0, 3), "***")
    partialDocument = partialDocument.replace(document.slice(-2), "**")
    return partialDocument
  }
}
