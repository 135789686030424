import { Charge, SearchResult } from "../../entities"
import { ChargeRepository } from "../../repositories/csg"
import UserStorage from "../../util/userStorage"

export class ChargeService {
  public static async searchByPlate(
    licensePlate: string
  ): Promise<SearchResult<Charge>> {
    const token = UserStorage.getToken()
    return await ChargeRepository.searchByPlate(licensePlate, token)
  }

  public static async searchByPlates(
    licensePlates: string[],
    status?: string | null
  ): Promise<SearchResult<Charge>> {
    const token = UserStorage.getToken()
    return await ChargeRepository.searchByPlates(licensePlates, token, status)
  }
}
