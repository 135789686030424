import { Vehicle } from "../entities"

const storageKey = "@vehicleSelected"
class VehicleSelectedStorage {
  static get = (): Vehicle[] => {
    const vehicles = localStorage.getItem(storageKey)
    return vehicles ? JSON.parse(vehicles) : []
  }

  static set = (vehicles: Vehicle[]) => {
    const vehiclesStringfy = localStorage.getItem(storageKey)
    const existingVehicles = (
      vehiclesStringfy ? JSON.parse(vehiclesStringfy) : []
    ) as Vehicle[]

    const uniqueVehicles = vehicles.filter(
      (newVehicle) =>
        !existingVehicles.some(
          (existingVehicle) => existingVehicle.id === newVehicle.id
        )
    )

    const updatedVehicles = [...existingVehicles, ...uniqueVehicles]

    localStorage.setItem(storageKey, JSON.stringify(updatedVehicles))
  }

  static add = (vehicle: Vehicle) => {
    const vehiclesStringfy = localStorage.getItem(storageKey)
    const vehicles = (
      vehiclesStringfy ? JSON.parse(vehiclesStringfy) : []
    ) as Vehicle[]

    vehicles.push(vehicle)

    localStorage.setItem(storageKey, JSON.stringify(vehicles))
  }

  static has = (vehicle: Vehicle) => {
    const vehiclesStringfy = localStorage.getItem(storageKey)
    const vehicles = (
      vehiclesStringfy ? JSON.parse(vehiclesStringfy) : []
    ) as Vehicle[]

    const hasVehicle = vehicles.find(
      (selectedVehicle) => selectedVehicle.id === vehicle.id
    )

    return !!hasVehicle
  }

  static remove = (vehicle: Vehicle) => {
    const vehiclesStringfy = localStorage.getItem(storageKey)
    const vehicles = (
      vehiclesStringfy ? JSON.parse(vehiclesStringfy) : []
    ) as Vehicle[]

    const index = vehicles.findIndex((data) => data.id === vehicle.id)

    if (index !== -1) {
      vehicles.splice(index, 1)
      localStorage.setItem(storageKey, JSON.stringify(vehicles))
    }
  }

  static clear = () => {
    localStorage.removeItem(storageKey)
  }
}

export default VehicleSelectedStorage
