import React, { useRef, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import InputMask from "react-input-mask"
import {
  Flex,
  Text,
  FormControl,
  Input,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Image,
  InputGroup,
  InputLeftElement,
  useToast,
  Select,
} from "@chakra-ui/react"
import { MinusIcon, AddIcon } from "@chakra-ui/icons"
import { BiCreditCard } from "react-icons/bi"
import ReCAPTCHA from "react-google-recaptcha"
import theme from "../../Themes"

interface PaymentTypeCreditCardProps {
  pay: any
}

export default function PaymentTypeCreditCard(
  props: PaymentTypeCreditCardProps
) {
  const toast = useToast()

  const recaptchaRef = useRef(null)

  const [form, setForm] = useState({
    number: "",
    holderName: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
  })
  const [token, setToken] = useState<string | null>(null)

  const pay = () => {
    if (!token) {
      toast({
        title: "Verifique o recaptcha para avançar",
        status: "error",
      })
      return
    }

    props.pay(form, token)
  }

  return (
    <Accordion
      allowToggle
      w="full"
      border="1px solid #E2E8F0"
      borderRadius="0.5rem"
      overflow="hidden"
      fontFamily="Raleway"
    >
      <AccordionItem bg={theme.color.tertiary}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton>
                <Flex
                  as="span"
                  flex="1"
                  textAlign="left"
                  alignItems="center"
                  gap="1rem"
                >
                  <Image
                    src="/images/card-icon/CreditCard.svg"
                    alt="Ícone de cartão de crédito"
                  />
                  <Text fontSize="0.875rem" fontWeight="700" color="#171923">
                    Pagar via cartão de crédito
                  </Text>
                </Flex>
                {isExpanded ? (
                  <MinusIcon fontSize="0.875rem" />
                ) : (
                  <AddIcon fontSize="0.875rem" />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex w="full" h="full" direction="column" gap="1rem">
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color={theme.color.primary}
                    >
                      <BiCreditCard />
                    </InputLeftElement>
                    <Input
                      as={InputMask}
                      mask="99999999999999999999"
                      maskChar={null}
                      size="md"
                      type="text"
                      value={form.number}
                      onChange={(event) =>
                        setForm({ ...form, number: event.target.value })
                      }
                      placeholder="Número do cartão"
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Image
                        src="/images/BluePersonNameIcon.png"
                        alt="Ícone de uma pessoa para informar o nome"
                      />
                    </InputLeftElement>
                    <Input
                      size="md"
                      type="text"
                      value={form.holderName}
                      onChange={(event) =>
                        setForm({ ...form, holderName: event.target.value })
                      }
                      placeholder="Nome impresso no cartão"
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <Flex w="100%" gap="0.5rem">
                    <InputGroup>
                      <Select
                        w="100%"
                        maxWidth="200px"
                        size="md"
                        placeholder="Mês de Validade"
                        value={form.expirationMonth}
                        onChange={(event) => {
                          let expirationMonth = event.target.value
                          if (parseInt(expirationMonth) > 12) {
                            toast({
                              title: "Digite um mês entre 1 e 12.",
                              status: "error",
                              duration: 2000,
                            })
                            expirationMonth = ""
                          }

                          setForm({
                            ...form,
                            expirationMonth: expirationMonth,
                          })
                        }}
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(
                          (item) => (
                            <option value={("00" + item).slice(-2)} key={item}>
                              {("00" + item).slice(-2)}
                            </option>
                          )
                        )}
                      </Select>
                    </InputGroup>
                    <InputGroup>
                      <Select
                        w="100%"
                        maxWidth="200px"
                        size="md"
                        placeholder="Ano de Validade"
                        value={form.expirationYear}
                        onChange={(event) => {
                          let expirationYear = event.target.value
                          const currentYear = new Date().getFullYear()

                          if (
                            expirationYear.length === 4 &&
                            parseInt(expirationYear) < currentYear
                          ) {
                            toast({
                              title: "Cartão expirado.",
                              status: "error",
                              duration: 2000,
                            })
                            expirationYear = ""
                          }

                          setForm({
                            ...form,
                            expirationYear: expirationYear,
                          })
                        }}
                      >
                        {Array.from(
                          { length: 20 },
                          (_, i) => i + new Date().getFullYear().valueOf()
                        ).map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </Select>
                    </InputGroup>
                  </Flex>
                </FormControl>

                <Flex w="full" gap="1rem" alignItems="center">
                  <FormControl w="full" maxW="200px">
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Image
                          src="/images/BlueCVVIcon.png"
                          alt="Ícone de um código para informar os dígitos do CVV"
                        />
                      </InputLeftElement>
                      <Input
                        as={InputMask}
                        mask="9999"
                        maskChar={null}
                        size="md"
                        type="text"
                        placeholder="CVV"
                        value={form.cvv}
                        onChange={(event) =>
                          setForm({
                            ...form,
                            cvv: event.target.value,
                          })
                        }
                        onBlur={(event) => {
                          if (form.cvv.length < 3) {
                            toast({
                              title: "Digite um CVV válido.",
                              status: "error",
                              duration: 2000,
                            })
                            setForm({ ...form, cvv: "" })
                          }
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                  {/* <Tooltip label="Tooltip test">
                    <AiOutlineQuestionCircle />
                  </Tooltip> */}
                </Flex>
                {/* <Checkbox colorScheme="orange" defaultChecked>
                  Salvar este cartão para pagamentos futuros
                </Checkbox>                 */}
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ""}
                  ref={recaptchaRef}
                  onChange={(token: any) => {
                    console.log("Captcha value:", token)
                    setToken(token)
                  }}
                />
                <Button
                  w="full"
                  size="md"
                  bg={theme.color.primary}
                  color={theme.color.tertiary}
                  p="0 1rem"
                  fontSize="0.875rem"
                  fontWeight="600"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="0.375rem"
                  onClick={pay}
                >
                  Confirmar pagamento
                </Button>
              </Flex>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
