import { Flex, Text, Checkbox } from "@chakra-ui/react"
import { PiCar } from "react-icons/pi"
import theme from "../../Themes"
import { Vehicle } from "../../entities"

interface SimpleCheckboxCardProps {
  vehicle: Vehicle
  onChange?: (vehicle: Vehicle) => void
  isChecked: boolean
}

export default function SimpleCheckboxCard(props: SimpleCheckboxCardProps) {
  return (
    <Flex
      w="100%"
      p="1rem"
      justifyContent="space-between"
      alignItems="center"
      border="1px solid"
      borderColor={props.isChecked ? `${theme.color.primary}` : "#E2E8F0"}
      borderRadius="0.5rem"
    >
      <Flex gap="2">
        <Flex
          bg="#E7ECFF"
          p="0.750rem"
          borderRadius="0.5rem"
          alignItems="center"
          fontSize="1.5rem"
          color="#3531C6"
        >
          <PiCar />
        </Flex>
        <Flex
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Text
            fontSize="0.875rem"
            fontWeight="600"
            color="#8E99AB"
            fontFamily="Raleway"
          >
            {props.vehicle.manufacturer} {props.vehicle.model}
          </Text>
          <Text
            fontSize="0.875rem"
            fontWeight="600"
            color="#2D3748"
            fontFamily="Raleway"
          >
            {props.vehicle.licensePlate}
          </Text>
        </Flex>
      </Flex>
      <Checkbox
        iconColor={theme.color.tertiary}
        _checked={{
          bg: "none",
          borderColor: `${theme.color.primary}`,
        }}
        sx={{
          ".chakra-checkbox__control": {
            borderColor: props.isChecked ? `${theme.color.primary}` : "#E2E8F0",
            bg: props.isChecked ? `${theme.color.primary}` : "transparent",
            transition: "0.3s ease-in-out",
            _hover: {
              bg: props.isChecked ? `${theme.color.quaternary}` : "none",
              borderColor: `${theme.color.quaternary}`,
            },
          },
        }}
        isChecked={props.isChecked}
        onChange={() => {
          props.onChange?.(props.vehicle)
        }}
      />
    </Flex>
  )
}
