import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  ModalFooter,
} from "@chakra-ui/react"

interface Props {
  title: string
  message: string
  isOpen?: any
  onOpen?: any
  onClose?: any
  action: any
}

export default function DeleteAccountModal(props: Props) {
  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{props.message}</ModalBody>
          <ModalFooter gap="1rem">
            <Button variant="ghost" onClick={props.onClose}>
              Cancelar
            </Button>
            <Button colorScheme="red" onClick={() => props.action()}>
              Remover
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
