import React from "react"
import { Route, Routes } from "react-router-dom"
import Login from "./auth/Login"
import ForgotPassword from "./forgot-password/ForgotPassword"
import EmailVerification from "./email-verification/EmailVerification"
import RedefinePassword from "./redefine-password/RedefinePassword"
import RedefinePasswordSuccess from "./redefine-password/RedefinePasswordSuccess"
import UserForm from "./user/form/UserForm"
import UserFormSuccess from "./user/form/UserFormSuccess"
import VehicleList from "./vehicle/list/VehicleList"
import VehicleForm from "./vehicle/form/VehicleForm"
import VehicleRequest from "./vehicle/request/VehicleRequest"
import VehicleRequestSent from "./vehicle/request/VehicleRequestSent"
import LoggedArea from "./logged-area/LoggedArea"
import ActivityDetails from "./logged-area/activity-details/ActivityDetails"
import PaymentDetails from "./payment-details/PaymentDetails"
import PaymentWaitingScreen from "./payment-details/PaymentWaitingScreen"
import PaymentConfirmedScreen from "./payment-details/PaymentConfirmedScreen"
import PaymentScreen from "./payment-details/PaymentScreen"
import AddBalance from "./add-balance/AddBalance"
import AccessPage from "./access/AccessPage"
import UserProfile from "./user/profile/UserProfile"
import RedeemBalanceConfirmed from "./user/balance/RedeemBalanceConfirmed"
import RedeemBalanceWaiting from "./user/balance/RedeemBalanceWaiting"
import UserProfileEdit from "./user/form/edit/UserProfileEdit"
import UserProfileChangePassword from "./user/form/edit/UserProfileChangePassword"
import RedeemBalance from "./user/balance/RedeemBalance"
import SplashScreen from "./access/SplashScreen"
import SmsVerification from "./sms-verification/SmsVerification"
import SmsVerificationCode from "./user/sms-verification-code/SmsVerificationCode"
import PaymentInfo from "./payment-details/PaymentInfo"
import PaymentMethod from "./payment-details/PaymentMethod"
import ChargeSearch from "./charge-search/ChargeSearch"
import ContactPage from "./contact/ContactPage"
import LoadingScreen from "./loading-screen/LoadingScreen"
import BlockedAccess from "./blocked-access/BlockedAccess"

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<AccessPage />} />
      <Route path="/splash-screen" element={<SplashScreen />} />
      <Route path="/pagina-de-acesso" element={<AccessPage />} />

      <Route path="/login" element={<Login />} />
      <Route path="/esqueci-minha-senha" element={<ForgotPassword />} />
      <Route path="/verificar-email" element={<EmailVerification />} />
      <Route path="/verificar-sms" element={<SmsVerification />} />
      <Route path="/redefinir-senha" element={<RedefinePassword />} />
      <Route
        path="/redefinir-senha/sucesso"
        element={<RedefinePasswordSuccess />}
      />
      <Route path="/login/blocked" element={<BlockedAccess />} />

      <Route path="/cadastrar" element={<UserForm />} />
      {/* <Route path="/ativar-conta/email" element={<EmailVerificationCode />} /> */}
      <Route path="/ativar-conta/sms" element={<SmsVerificationCode />} />
      <Route path="/cadastrar/sucesso" element={<UserFormSuccess />} />

      <Route path="/veiculo" element={<VehicleList />} />
      <Route path="/veiculo/form" element={<VehicleForm />} />
      <Route path="/veiculo/solicitacao/:id" element={<VehicleRequest />} />
      <Route
        path="/veiculo/solicitacao/sucesso"
        element={<VehicleRequestSent />}
      />

      <Route path="/area-logada" element={<LoggedArea />} />

      <Route path="/detalhes-das-atividades" element={<ActivityDetails />} />

      <Route path="/busca" element={<ChargeSearch />} />
      {/* <Route path="/busca/:licensePlate" element={<ChargeResultSearch />} /> */}

      <Route path="/checkout" element={<PaymentDetails />} />
      <Route path="/checkout/info" element={<PaymentInfo />} />
      <Route path="/checkout/pagamento" element={<PaymentMethod />} />

      <Route
        path="/detalhes-de-pagamentos/pix/:orderId"
        element={<PaymentScreen />}
      />
      <Route
        path="/detalhes-de-pagamentos/aguardando"
        element={<PaymentWaitingScreen />}
      />
      <Route
        path="/detalhes-de-pagamentos/confirmado/:orderId"
        element={<PaymentConfirmedScreen />}
      />
      <Route path="/adicionar-saldo" element={<AddBalance />} />

      <Route path="/perfil" element={<UserProfile />} />
      <Route path="/perfil/editar" element={<UserProfileEdit />} />
      <Route
        path="/perfil/alterar-senha"
        element={<UserProfileChangePassword />}
      />

      <Route path="/perfil/resgatar-saldo" element={<RedeemBalance />} />
      <Route
        path="/perfil/resgatar-saldo/aguardando"
        element={<RedeemBalanceWaiting />}
      />
      <Route
        path="/perfil/resgatar-saldo/confirmado"
        element={<RedeemBalanceConfirmed />}
      />
      <Route path="/contato" element={<ContactPage />} />

      <Route path="/remover-conta" element={<ContactPage />} />

      <Route path="/loading-screen" element={<LoadingScreen />} />
    </Routes>
  )
}

export default Router
