import React, { useEffect, useState } from "react"
import InputMask from "react-input-mask"
import { useNavigate } from "react-router-dom"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react"
import Layout from "../../../components/layout/Layout/Layout"
import { Vehicle } from "../../../entities"
import { VehicleService } from "../../../services/csg"
import { UserService } from "../../../services"
import Loader from "../../../components/Loader/Loader"
import theme from "../../../Themes"

export default function VehicleForm() {
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState<Vehicle>(new Vehicle())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      console.log("is-valid-user", isValid)
      if (!isValid) {
        navigate("/login")
        return
      }
    })
    // eslint-disable-next-line
  }, [])

  const save = () => {
    if (form.licensePlate.length < 7) {
      toast({
        title: "Digite a placa do seu veículo.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.manufacturer.length < 2) {
      toast({
        title: "Digite a Fabricante.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.model.length < 2) {
      toast({
        title: "Digite o Modelo.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.color.length < 2) {
      toast({
        title: "Digite a Cor.",
        status: "error",
        duration: 2000,
      })
      return
    }

    const newVehicle = new Vehicle(form)

    setIsLoading(true)
    VehicleService.create(newVehicle)
      .then((vehicle) => {
        navigate("/veiculo")
      })
      .catch((error) =>
        toast({
          title: error?.response?.data?.message,
          status: "error",
        })
      )
      .finally(() => setIsLoading(false))
  }

  return (
    <Layout
      internal={true}
      header={{
        title: "Cadastrar veículo",
        backTo: "/veiculo",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
      menuSelected="vehicle"
    >
      {isLoading && <Loader />}
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
      >
        <Flex>
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Cadastrar novo veículo
          </Text>
        </Flex>

        <FormControl isRequired>
          <FormLabel>Placa do seu veículo</FormLabel>
          <Input
            textTransform="uppercase"
            maxLength={8}
            size="lg"
            type="text"
            value={form.licensePlate}
            onChange={(event) =>
              setForm({ ...form, licensePlate: event.target.value })
            }
            placeholder="ABC 9999"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Fabricante</FormLabel>
          <Input
            size="lg"
            type="text"
            value={form.manufacturer}
            maxLength={12}
            onChange={(event) =>
              setForm({ ...form, manufacturer: event.target.value })
            }
            placeholder="Ex.: Volkswagen"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Modelo</FormLabel>
          <Input
            size="lg"
            type="text"
            value={form.model}
            maxLength={12}
            onChange={(event) =>
              setForm({ ...form, model: event.target.value })
            }
            placeholder="Ex.: Gol"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Cor</FormLabel>
          <Input
            size="lg"
            type="text"
            value={form.color}
            maxLength={12}
            onChange={(event) =>
              setForm({ ...form, color: event.target.value })
            }
            placeholder="Ex.: Prata"
          />
        </FormControl>

        <Flex>
          <Button
            w="100%"
            size="lg"
            fontSize="1rem"
            mt="3"
            bg={theme.color.primary}
            color={theme.color.tertiary}
            _hover={{ bg: `${theme.color.quaternary}`, transition: "0.3s" }}
            onClick={save}
          >
            Cadastrar
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}
