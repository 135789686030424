import { SearchParams, SearchResult } from "../../entities"
import { TransactionalLog } from "../../entities/csg/TransactionalLog"
import { TransactionalLogRepository } from "../../repositories/csg/TransactionalLogRepository"
import UserStorage from "../../util/userStorage"

export class TransactionalLogService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<TransactionalLog>> {
    const token = UserStorage.getToken()
    return await TransactionalLogRepository.search(params, token)
  }

  public static async readOne(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await TransactionalLogRepository.readOne(id, token)
  }

  public static async readAll(): Promise<boolean> {
    const token = UserStorage.getToken()
    return await TransactionalLogRepository.readAll(token)
  }

  public static async get(id: number): Promise<TransactionalLog> {
    const token = UserStorage.getToken()
    return await TransactionalLogRepository.get(id, token)
  }

  public static async delete(id: number): Promise<boolean> {
    const token = UserStorage.getToken()
    return await TransactionalLogRepository.delete(id, token)
  }
}
