import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { Flex, Image, Link as ChakraLink } from "@chakra-ui/react"
import theme from "../../Themes"

export default function SplashScreen() {
  return (
    <Flex
      minH="100vh"
      bg={theme.color.primary}
      alignItems="center"
      justifyContent="center"
    >
      <ChakraLink
        mb="10rem"
        as={ReactRouterLink}
        target="_blank"
        to="http://site.dev.csg.attri.com.br/"
      >
        <Image
          src={theme.logoWhite.url}
          alt={`Logo da ${theme.company.name}`}
        />
      </ChakraLink>
    </Flex>
  )
}
